import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, ButtonGroup, Container } from 'react-bootstrap';
import { Person, Plus, X } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { ABORT_ERROR } from '../../helpers/api';
import { APARTMENT_STATUS } from '../../helpers/constants';
import { apartmentTypes, updateItemsInArray } from '../../helpers/global';
import { apartmentTableColumns } from '../../helpers/overviewColumnsFields';
import useAuth from '../../hooks/useAuth';
import useDebouncedEffect from '../../hooks/useDebouncedEffect';
import useLocalization from '../../hooks/useLocalization';
import { apartmentServices } from '../../services/appartmentServices';
import CustomerSelectModal from '../calculators/CustomerSelectModal';
import AlertModal from '../common/AlertModal';
import DataTable from '../common/data-table/DataTable';
import FloatingButton from '../common/FloatingButton';
import AddEditApartmentModal from './AddEditApartmentModal';

const DEFAULT_PAGE_SIZE = 50;

const defaultTableSortOption = {
  key: 'createdAt',
  order: 'desc'
};
const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1
};

const getTableFilterValues = (fields, apartmentProfile) => {
  if (!apartmentProfile) return [];
  const filters = [];

  fields?.forEach(({ type, key, apartmentProfileKey }) => {
    if (apartmentProfile.hasOwnProperty(apartmentProfileKey || key)) {
      const value = apartmentProfile[apartmentProfileKey || key];

      if (type === 'text') {
        filters.push({ type, key, value: value || '' });
      }
      if (type === 'number') {
        let from = value?.min;
        let to = value?.max;
        if (key === 'areaInSquareMeters') {
          from = value === 'No' ? 50 : null;
          to = value === 'Yes' ? 50 : null;
        }
        if (from || to) {
          filters.push({
            type,
            key,
            value: { from, to }
          });
        }
      }
    }
  });

  return filters;
};

const BrowseAppartments = ({ adminCompTable }) => {
  const abortControllerRef = useRef(null);

  const { user } = useAuth();
  const { translate } = useLocalization();

  const [tableSortOption, setTableSortOption] = useState(defaultTableSortOption);
  const [tableFilterValues, setTableFilterValues] = useState([]);

  const [fetchingFirstPageApartments, setFetchingFirstPageApartments] = useState(false);
  const [fetchingMoreApartments, setFetchingMoreApartments] = useState(false);
  //original apartment array
  const [apartments, setApartments] = useState([]);
  const [isFetchingApartments, setIsFetchingApartments] = useState(false);
  const [toLoadPageInfo, setToLoadPageInfo] = useState();
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const [addEditApratmentModalMeta, setAddEditApartmentModalMeta] = useState(null);
  const [deleteApartmentMeta, setDeleteApartmentMeta] = useState(null);
  const [creatingDriveFolder, setCreatingDriveFolder] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [apartmentProfile, setApartmentProfile] = useState(null);
  const [showCustomerSelectionModal, setShowCustomerSelectionModal] = useState(false);

  const onAddNewApartmentClick = () => {
    setAddEditApartmentModalMeta({
      mode: 'add',
      data: { type: apartmentTypes[0]?.value, status: APARTMENT_STATUS[0] }
    });
  };

  const onEditApartmentClick = apartment => {
    setAddEditApartmentModalMeta({
      mode: 'update',
      data: apartment
    });
  };

  const onDeleteApartmentClick = apartmentTodelete => {
    setDeleteApartmentMeta({ apartmentTodelete });
  };

  const tableColumns = useMemo(() => apartmentTableColumns({ translate }), [translate]);

  const deleteApartment = async () => {
    try {
      let apartmentTodelete = deleteApartmentMeta?.apartmentTodelete;
      setDeleteApartmentMeta({ ...deleteApartmentMeta, showProgress: true });
      let { response, error } = await apartmentServices.deleteApartment(apartmentTodelete?._id);
      if (error) {
        setDeleteApartmentMeta({ ...deleteApartmentMeta, showProgress: false });
        return toast.error(error);
      } else {
        let newApartments = apartments?.filter(a => a._id !== apartmentTodelete?._id);
        setApartments(newApartments);
        setDeleteApartmentMeta(null);
        setAddEditApartmentModalMeta(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApartments = async () => {
    setIsFetchingApartments(true);
    if (!toLoadPageInfo) {
      setIsFetchingApartments(false);
      return;
    }

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const pageToFetch = toLoadPageInfo?.pageNumber;

    const loadingMoreApartments = pageToFetch > 1;

    if (loadingMoreApartments) {
      setFetchingMoreApartments(true);
    } else {
      setFetchingFirstPageApartments(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
      sort: tableSortOption,
      filter: tableFilterValues
      // customer: selectedCustomer
    };

    const { response, error } = await apartmentServices.getApartments(requestBody, signal);
    if (error === ABORT_ERROR) return;

    setFetchingMoreApartments(false);
    setFetchingFirstPageApartments(false);

    if (error) {
      setIsFetchingApartments(false);
      toast.error(error);
      return;
    }

    const { pageNumber: pgNumber, totalPages, resultCount, results } = response;

    setLoadedPageInfo({
      totalPages,
      pageNumber: pgNumber,
      resultCount
    });

    setApartments(prevApartments => {
      const newArray = loadingMoreApartments ? [...prevApartments, ...results] : results;

      return newArray;
    });

    setIsFetchingApartments(false);
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageApartments || fetchingMoreApartments) return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
      scrollHeight: undefined
    });
  };

  const createDriveFolderInApartment = async apartmentID => {
    try {
      setCreatingDriveFolder(true);
      let { response, error } = await apartmentServices.createDriveFolder({
        _id: apartmentID
      });
      if (error) {
        return toast.error(error);
      }
      if (response) {
        toast.success('Drive folder has been created');
        let updatedApartments = updateItemsInArray(apartments, response);
        setApartments(updatedApartments);
        if (Boolean(addEditApratmentModalMeta)) {
          setAddEditApartmentModalMeta({ ...addEditApratmentModalMeta, data: response });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCreatingDriveFolder(false);
    }
  };

  const handleAddUpdateApartment = async (app, silentUpdate = false) => {
    try {
      setAddEditApartmentModalMeta({ ...addEditApratmentModalMeta, showProgress: true });
      let editMode = addEditApratmentModalMeta?.mode === 'update';
      let { response, error } = editMode
        ? await apartmentServices.updateApartment(app?._id, app)
        : await apartmentServices.createApartment(app);
      if (error) {
        setAddEditApartmentModalMeta({ ...addEditApratmentModalMeta, showProgress: false });
        return toast.error(error);
      }
      if (response) {
        toast.success(editMode ? 'Apartment has been updated' : 'New apartment has been added');
        let updatedApartments = updateItemsInArray(apartments, response);
        setApartments(updatedApartments);
        if (silentUpdate) {
          setAddEditApartmentModalMeta({ ...addEditApratmentModalMeta, showProgress: false, data: response });
        } else {
          setAddEditApartmentModalMeta(null);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchApartments({});
  }, [toLoadPageInfo]);

  useEffect(() => {
    let updatedTableFilterValues = getTableFilterValues(tableColumns, apartmentProfile);
    setTableFilterValues(updatedTableFilterValues);
  }, [apartmentProfile, tableColumns]);

  useDebouncedEffect(
    () => {
      setToLoadPageInfo({ ...initialPageInfo, scrollHeight: 0 });
    },
    [tableFilterValues, tableSortOption],
    500
  );

  return (
    <>
      <Container fluid className={'px-2'}>
        <div className="pt-2">
          <div className="d-flex justify-content-between align-items-center mb-2">
            {' '}
            <h6 className="mb-0">Apartments ({loadedPageInfo?.resultCount || 0})</h6>
            <div className="d-flex align-items-center gap-1">
              <h6 className="mid mb-0 fw-bold">{translate('show_apartments_for')}:</h6>
              <ButtonGroup className="m-0">
                <Button
                  className="d-inline-flex align-items-center  fw-bold mx-0"
                  variant="outline-dark"
                  size="sm"
                  onClick={() => setShowCustomerSelectionModal(true)}
                  style={{ fontSize: 12 }}
                >
                  <Person className="mx-1" />
                  {selectedCustomer ? selectedCustomer.name : translate('select_customer')}
                </Button>{' '}
                {selectedCustomer &&
                  selectedCustomer?.apartmentProfiles?.length > 0 &&
                  selectedCustomer?.apartmentProfiles?.map((a, i) => {
                    return (
                      <Button
                        className="d-flex align-items-center mx-0"
                        variant={apartmentProfile?._id === a?._id ? 'dark' : 'outline-dark'}
                        size="sm"
                        onClick={() => setApartmentProfile(a)}
                      >
                        P{i + 1}
                      </Button>
                    );
                  })}
                {selectedCustomer && (
                  <Button
                    className="d-flex align-items-center  fw-bold"
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      setApartmentProfile(null);
                      setSelectedCustomer(null);
                    }}
                  >
                    <X size={16} />
                  </Button>
                )}
              </ButtonGroup>
            </div>
          </div>

          <DataTable
            maxTableHeight={`calc(100vh - 100px)`}
            rowKey={'_id'}
            columns={tableColumns}
            data={[...apartments, ...apartments, ...apartments, ...apartments]}
            bottomOffset={300}
            onBottomReached={loadMoreData}
            onRowClick={onEditApartmentClick}
            loadingMoreData={fetchingMoreApartments}
            loadingFirstPageData={fetchingFirstPageApartments}
            sortOptions={tableSortOption}
            onSortChange={setTableSortOption}
            filterValues={tableFilterValues}
            onFilterValuesChange={setTableFilterValues}
          />
        </div>
        {addEditApratmentModalMeta && (
          <AddEditApartmentModal
            show={Boolean(addEditApratmentModalMeta)}
            onHide={() => setAddEditApartmentModalMeta(null)}
            initialData={addEditApratmentModalMeta?.data}
            adminCompTable={adminCompTable}
            editMode={addEditApratmentModalMeta?.mode === 'update'}
            onSubmit={handleAddUpdateApartment}
            onDeleteApartmentClick={onDeleteApartmentClick}
            showProgress={addEditApratmentModalMeta?.showProgress}
            createDriveFolderInApartment={createDriveFolderInApartment}
            creatingDriveFolder={creatingDriveFolder}
          />
        )}
        <CustomerSelectModal
          show={showCustomerSelectionModal}
          onHide={() => setShowCustomerSelectionModal(false)}
          selectedCustomer={selectedCustomer}
          onCustomerSelect={cust => {
            if (cust?.apartmentProfiles?.length > 0) {
              setSelectedCustomer(cust);
              setApartmentProfile(cust?.apartmentProfiles[0]);
              setShowCustomerSelectionModal(false);
            } else {
              toast.info('This customer does not have any profile associated');
            }
          }}
          showSubmitButton={false}
          headerText="Select customer"
        />

        <AlertModal
          show={Boolean(deleteApartmentMeta)}
          onHide={() => setDeleteApartmentMeta(null)}
          onDismissClick={() => setDeleteApartmentMeta(null)}
          onContinueClick={deleteApartment}
          showProgress={deleteApartmentMeta?.showProgress}
          alertText={'Are you sure you want to delete this apartment?'}
        />
        <FloatingButton
          variant="success"
          className="text-white"
          Icon={Plus}
          onClick={onAddNewApartmentClick}
          text={'New apartment'}
        />
      </Container>
    </>
  );
};

export default BrowseAppartments;
